[role=tooltip].popup-content{
    width: 400px;
}

[role=tooltip].darkmode-popup-content{
    background-color: #121212;
    color: #e1e1e1;
}

.mic-details caption{
    padding: 8px;
    border-bottom: 1px #ccc solid;
    font-family: 'SFProDisplaySemibold', Arial, sans-serif;
}

.mic-details th, .mic-details td{
    text-align: left;
    padding: 8px;
}

.mic-details tr{
    border-bottom: 1px #ccc solid;
}

.mic-details tr:last-child{
    border-bottom: none;
}
