.mic-report table{
    border-spacing: none;
    border: 1px solid black;
}

.mic-report caption{
    padding: 8px;
    border: 1px solid black;
    border-bottom: none;
    font-family: 'SFProDisplaySemibold', Arial, sans-serif;
}

.mic-report tr:nth-child(even){
    background-color: #f2f1f1;
}

.mic-report tr:nth-child(odd):hover{
    background-color: #f2f1f1;
}

.mic-report tr:nth-child(even):hover{
    background-color: #ffffff;
}


.mic-report th, .mic-report td{
    margin: none;
    padding: 0.5rem;
    border: 1px solid black;
}

.mic-report.darkMode table {
    border-spacing: none;
    color:white;
    border: 1px solid white;
}

.mic-report.darkMode caption {
    padding: 8px;
    border: 1px solid white;
    color: white;
    border-bottom: none;
    font-family: 'SFProDisplaySemibold', Arial, sans-serif;
}

.mic-report.darkMode tr:nth-child(even) {
    background-color: var(--a-main-bg);
    color: white;
}

.mic-report.darkMode tr:nth-child(odd) {
    background-color:   #000000;
    color: white;
}

.mic-report.darkMode tr:nth-child(odd):hover {
    background-color: #000000;
    color: white;
}

.mic-report.darkMode tr:nth-child(even):hover {
    background-color: #000000;
    color: white;
}

.mic-report.darkMode th, .mic-report.darkMode td {
    margin: none;
    padding: 0.5rem;
    border: 1px solid white;
}

.mic-report.darkMode strong {
    color: white;
    font-family: 'SFProDisplaySemibold', Arial, sans-serif;
}

.mic-report strong{
    font-family: 'SFProDisplaySemibold', Arial, sans-serif;
}
