/* LAYOUT AND SNAP WINDOW */

.single-view #left-column-imagequery {
  padding-left: 0px;
  padding-right: 20px;
  min-width: 340px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.grid-view #left-column-imagequery {
  display: flex;
  flex-direction: row;
}

.grid-view #left-column-imagequery > * {
  padding-right: 20px;
}

.imagequery-top-box{
  flex-grow: 1;
}
.imagequery-top-box * {
  margin-top: 10px;
}

.imagequery-middle-box{
  flex-grow: 2;
}
.imagequery-middle-box .error-msg{ 
  margin-top: 10px;
  height: 2em;
}
.imagequery-jumbotron {
  border-radius: 5px;
  width: 100%;
}

.grid-view .imagequery-middle-box, .grid-view .imagequery-bottom-box {
  padding-top: 32px;
}

/* DATE, TIME PICKERS */
.date-time-pickers-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.datepicker {
  /* flex: none; */
  width: 66%;
}

/* the datepicker calendar element itself */
.sc-ifAKCX.klnTzu {
  z-index: 999;
}

.timepicker {
  /* flex: none; */
  width: 33%;
}
.timepicker .rc-time-picker {
  width: 100%;
  display: block;
}

.datepicker input, .timepicker .rc-time-picker input {
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 7px 12px;
  background-color: var(--text-input-bg);
  border-radius: 3px;
  border: 1px solid var(--thin-divider);
  margin-left: 0px;
  margin-right: 0px;
  

  font-size: 18px;
  font-family: "SFProDisplayLight", Arial, sans-serif;
  color: var(--info-text);
  cursor: pointer;
}

/* CONTROL BUTTONS < > */
.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.imagequery-go-button {
  margin-top: 10px;
}

.imagequery-room-title {
  font-size: 50px;
}

.imagequery-top-box input, .imagequery-top-box h2 {
  margin: 5px 0px 0px 0px;
}

.imagequery-control-button {
  width: 40px;
  display: flex;
  justify-content: center;
}


/* SLIDER CUSTOM STYLES */
.horizontal-slider {
  height: 10px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.slider-thumb .thumb-grab {
  background-color: var(--button-bg);

  border-radius: 50%;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  cursor: grab;
}
.thumb-date {
  color: var(--info-text);
  /* white-space: nowrap; */
  margin-left: 0px;
}

.slider-track {
  background-color: var(--a-main-bg);
  top: 0;
  bottom: 0;
}


/* TIMEPICKER CUSTOM COLORS / STYLES */
.rc-time-picker-panel-inner, .rc-time-picker-panel-input {
  background-color: var(--main-bg);
}

.rc-time-picker-panel-inner {
  /* drop shadow */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  color: var(--info-text);
}

li.rc-time-picker-panel-select-option-selected, .rc-time-picker-panel-select li:hover {
  background-color: var(--a-main-bg);
  color: var(--a-info-text);
}

.rc-time-picker-clear-icon:after {
  font-size: 15px;
  color: var(--info-text);
}
.rc-time-picker-clear-icon:hover:after {
  color: var(--a-info-text);
}

.to-from-box {
  width: 3.5em;
  height: 1.6em;
  display: grid;
  justify-content: right;
  align-items: center;
}


/* VIEW SELECTION */
.view-container {
  margin-top: 10px;
}

.active-view-button {
  background-color: var(--a-button-bg);
}

/* IMAGE GRID */
/* .interior-grid-snap-div {
  position: relative;
  
} */

/* .interior-grid-snap-div:hover {
  top: -25%;
  left: -25%;
} */

/* .grid-snap {
  width: 100%;
  height: auto;
  position: relative;
  transition-property: left, top, width;
  top: 0%;
  left: 0%;
  transition-duration: 0.2s;
}



.grid-snap:hover {
  width: 110%;
  left: -5%;
  top: -3%;
  z-index: 999;
} */

.grid-snap-card {
  background-color: var(--text-input-bg);
  border-radius: 3px;
  border: 1px solid var(--thin-divider);
  padding: 3px;
}

.grid-snap-img {
  width: 100%;
  height: auto;
  border-radius: 3px;
  
  cursor: pointer;


  display: none; /* don't display until loaded */
}

.grid-snap-time-string {
  padding: 10px;
  overflow: scroll;
}

.grid-snap-img.fade-in {
  display: initial; /* when we add .fade-in, the image has been loaded. */
}