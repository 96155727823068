:root {
  --fc-page-bg-color: var(--main-bg);
  --fc-border-color: var(--thin-divider);

  --fc-button-text-color: var(--info-text);
  --fc-button-bg-color: var(--button-bg);
  --fc-button-border-color: var(--thin-divider);
  --fc-button-hover-bg-color: var(--a-button-bg);
  --fc-button-hover-border-color: var(--thin-divider);
  --fc-button-active-bg-color: var(--info-text);
  --fc-button-active-border-color: var(--thin-divider);

  --fc-event-bg-color: var(--light-blue);
  --fc-event-border-color: var(--light-blue);
  --fc-event-text-color: var(--info-text);

  --fc-today-bg-color: var(--a-main-bg);
  --fc-now-indicator-color: var(--red);
}

/* BUTTONS in HEADER BAR */
/* make active buttons have correct text color */
.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
  color: var(--main-bg);
}
/* Prevent a z-index issue on hovered/active buttons*/
.fc .fc-button-group>.fc-button.fc-button-active, .fc .fc-button-group>.fc-button:active, 
.fc .fc-button-group>.fc-button:focus, .fc .fc-button-group>.fc-button:hover {
  z-index: 0;
}


/* HEADERS */
.fc a {
  color: var(--header);
}


/* HEADER BUTTONS */
.fc .fc-button-primary:focus, 
.fc .fc-button-primary:not(:disabled):active:focus, 
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}

/* [ today ] --> <-- [ < | > ]    (make the space between today and chevrons smaller) */
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 5px;
}

/* TABLE */
/* header divider in table */
.fc-unthemed .fc-divider {
  background-color: var(--thin-divider);
}
/* add some padding to the header labels */
.fc th {
  padding: 5px;
  font-family: "SFProDisplaySemibold", Arial, sans-serif;
}

/* > ----    (now indicator)*/
.fc .fc-timegrid-now-indicator-line {
  border-width: 2px 0 0 ;
}



/* EVENTS */

/* make the time full-width */
.fc-direction-ltr .fc-daygrid-event .fc-event-time{
  overflow: visible;
  margin-right: 1px;
}
.fc-event-main-frame{
  overflow: hidden;
}
div.fc-daygrid-event {
  font-size: .85em;
}



a.prof-link{
  color: var(--header-color);
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8em;
}

a.prof-link:hover{
  color: var(--a-info-text);
  text-decoration: underline;
}



/* CUSTOM EVENT COLUMNS */

/* Reset the positioning */
.fc-timegrid-event-harness, 
.fc-timegrid-event-harness.fc-timegrid-event-harness-inset{
  left: 0% !important; /* !important to override inline styles */
  right: 0% !important;
  width: 100%;
}

.fc-media-screen .fc-evt-classroom{
  left: 0 !important;
  width: var(--non-wixel-event-width);
}
.fc-media-screen .fc-evt-crestron{
  left: var(--proj1-left) !important;
  width: calc(var(--non-wixel-event-width) * var(--num-projectors));
}
.fc-media-screen .fc-evt-proj1{
  left: var(--proj1-left) !important;
  width: var(--non-wixel-event-width);
}
.fc-media-screen .fc-evt-proj2{
  left: calc(var(--proj1-left) + var(--non-wixel-event-width)) !important;
  width: var(--non-wixel-event-width);
}

.fc-media-screen .fc-evt-base1-both {
  left: var(--wixel-left) !important;
  width: calc(var(--wixel-width) * 2);
}
.fc-media-screen .fc-evt-base1-left {
  left: var(--wixel-left) !important;
  width: var(--wixel-width);
}
.fc-media-screen .fc-evt-base1-right {
  left: calc(var(--wixel-left) + var(--wixel-width)) !important;
  width: var(--wixel-width);
}

.fc-media-screen .fc-evt-base2-both {
  left: calc(var(--wixel-left) + var(--wixel-width) * 2) !important;
  width: calc(var(--wixel-width) * 2);
}
.fc-media-screen .fc-evt-base2-left {
  left: calc(var(--wixel-left) + var(--wixel-width) * 2) !important;
  width: var(--wixel-width);
}
.fc-media-screen .fc-evt-base2-right {
  left: calc(var(--wixel-left) + var(--wixel-width) * 3) !important;
  width: var(--wixel-width);
}




/* CALENDAR GRID - SPECIFIC CHANGES */

/* Header colors */
.fc .fc-timeline-slot-frame a {
  color: var(--header-color);
}