/* INPUT STYLING */

.mrs-crestron .crestron-input {
  box-sizing: content-box;

  font-size: 16px;
  padding: 7px 12px;
  background-color: var(--text-input-bg);
  border-radius: 3px;
  border: 1px solid var(--thin-divider);
  color: var(--info-text);
  height: 18px;
}


.mrs-crestron .crestron-input::placeholder {
  color: var(--info-text);
}

.mrs-crestron .crestron-input:focus, .mrs-crestron .crestron-input:hover {
  border: 1px solid var(--a-text-input-border);
  background-color: var(--a-text-input-bg);
  outline: none;
  color: var(--a-info-text);
}


.mrs-crestron .input-small {
  width: 30px;
}
.mrs-crestron .dropdown {
  width: 48px;
}
.mrs-crestron .input-large {
  width: 70px;
}



/* TABLE ROWS, BOXES, HEADERS */

.mrs-crestron table {
  margin-top: 30px;
}
.mrs-crestron td {
  padding: 3px 0px 3px 5px;
  font-size: 16px;
  color: var(--info-text);
}
.mrs-crestron tr:nth-child(even) {
  background-color: var(--a-main-bg);
}
.mrs-crestron thead tr th {
  background-color: var(--a-main-bg);
  padding: 5px;
  vertical-align: bottom;
  font-size: 16px;
  color: var(--thick-divider);
}

/* save,cancel buttons */
.save-and-cancel-buttons {
  width: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.save-button, .cancel-button {
  color: var(--dark-blue);
  cursor: pointer;
}
.save-button:hover, .cancel-button:hover {
  filter: brightness(120%);
}